import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { persistor } from '@base/redux/store';
import { useAppDispatch } from '@base/redux/hooks';
import { useLogoutMutation } from '@base/redux/features/auth';
import { logout as userLogout } from '@base/redux/reducers/userSlice';
import { logout as authLogout } from '@base/redux/reducers/authSlice';
import apiSlice from '@base/redux/apiSlice';
import { Spinner } from 'react-activity';

const LogoutButton: FC = () => {
    const dispatch = useAppDispatch();
    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const [logoutTrigger, { isSuccess, isError }] = useLogoutMutation();

    const logoutHandler = () => {
        dispatch(userLogout());
        dispatch(authLogout());
        dispatch(apiSlice.util.resetApiState());
        persistor.purge();
        persistor.persist();
    };

    const logout = () => {
        setLoggingOut(true);

        if (import.meta.env.VITE_AUTH_FLOW === 'pkce') {
            logoutHandler();

            return;
        }

        logoutTrigger();
    };

    useEffect(() => {
        if (isSuccess) {
            logoutHandler();
        } else if (isError) {
            setLoggingOut(false);
        }
    }, [isSuccess, isError]);

    return (
        <Button
            disabled={loggingOut}
            sx={{ py: 1.5 }}
            onClick={logout}
            size={'large'}
            fullWidth
            startIcon={loggingOut ? <Spinner /> : <Logout />}
        >
            Logout
        </Button>
    );
};

export default LogoutButton;
