import { FC, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
} from '@mui/material';
import UserSubscriptionComponent from '@base/components/Billing/User/UserSubscription';
import UserSubscriptionChip from '@base/components/Billing/User/UserSubscriptionChip';
import UserStorageBar from '@base/components/Users/UserStorageBar';

type UserSubscriptionModalProps = {
    subscription?: UserSubscription | null;
    onClose?: () => void;
    dialogOnly?: boolean;
    message?: string;
};

const UserSubscriptionModal: FC<UserSubscriptionModalProps> = ({ subscription, onClose, dialogOnly, message }) => {
    const [open, setOpen] = useState<boolean>(dialogOnly ?? false);
    const [processing, setProcessing] = useState<boolean>(false);

    const handleClose = () => {
        if (!processing) {
            setOpen(false);
            onClose && onClose();
        }
    };

    return (
        <>
            {!dialogOnly && (
                <Button
                    fullWidth={true}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    onClick={() => setOpen(true)}
                >
                    Get more storage
                </Button>
            )}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    Subscription:
                    <Stack
                        sx={{ marginLeft: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                        direction={'row'}
                        spacing={2}
                    >
                        <UserSubscriptionChip {...{ subscription }} />
                        <UserStorageBar {...{ compact: true }} />
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    {message && (
                        <>
                            <Alert severity={'info'}>
                                <AlertTitle>{message}</AlertTitle>
                            </Alert>
                            <Divider sx={{ my: 2 }} />
                        </>
                    )}
                    <UserSubscriptionComponent {...{ setProcessing }} />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserSubscriptionModal;
