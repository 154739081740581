import apiSlice from '@base/redux/apiSlice';

const userBilling = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUserInvoices: builder.mutation<UserInvoice[], null>({
            query: () => ({
                url: 'user/billing/invoices',
                method: 'GET',
            }),
        }),
        getUserBilling: builder.mutation<UserBillingPortalResponse, null>({
            query: () => ({
                url: 'user/billing/portal',
                method: 'GET',
            }),
        }),
        userCheckout: builder.mutation<UserCheckoutResponse, UserCheckoutRequest>({
            query: ({ price }) => ({
                url: 'user/billing/checkout',
                method: 'POST',
                body: { price },
            }),
        }),
    }),
});

export default userBilling;
export const { useGetUserInvoicesMutation, useGetUserBillingMutation, useUserCheckoutMutation } = userBilling;
