import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '@base/redux/hooks';
import { useWorkspace } from '@base/contexts/WorkspaceContext';
import OrganizationSidebar from '@base/layout/sidebar/OrganizationSidebar';
import UserSidebar from '@base/layout/sidebar/UserSidebar';
import {
    Avatar,
    Badge,
    Divider,
    Link,
    ListItemAvatar,
    MenuItem,
    MenuList,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useGetAllOrganizationsQuery } from '@base/redux/features/organization';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import AddOrganization from '@base/components/Organizations/AddOrganization';
import { AdminPanelSettings, Close, Pending } from '@mui/icons-material';
import imagePaths from '@base/hooks/imagePaths';
import AdminMenu from '@base/layout/sidebar/AdminMenu';

type SidebarProps = {
    inDrawer?: boolean;
    toggleDrawer?: () => void;
    admin?: boolean;
};

const Sidebar: FC<SidebarProps> = ({ inDrawer, toggleDrawer, admin }) => {
    const { isViewingOrg, organization } = useWorkspace();
    const location = useLocation();
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const { classes } = useStyles({ inDrawer });
    const user = useAppSelector((state) => state.user);
    const auth = useAppSelector((state) => state.auth);
    const { data: organizations } = useGetAllOrganizationsQuery(null, {
        skip: !auth.status || !user.data?.is_verified,
    });

    const handleOrganizationClicked = () => {
        if (userMenuOpen) {
            setUserMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isViewingOrg && organization?.id && userMenuOpen) {
            setUserMenuOpen(false);
        }
    }, [organization?.id, isViewingOrg, location.pathname]);

    if (!auth.status) {
        return null;
    }

    return (
        <Stack
            id={'sidebar'}
            className={classes.sidebar}
            divider={<Divider orientation={'vertical'} flexItem />}
            direction={'row'}
        >
            <div className={classes.leftColumn}>
                {user.data && (
                    <MenuList className={classes.leftColumnMenu}>
                        {inDrawer && (
                            <Tooltip placement={'right'} title={<Typography>Close</Typography>} arrow>
                                <MenuItem onClick={toggleDrawer}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Close sx={{ fontSize: 28 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                </MenuItem>
                            </Tooltip>
                        )}
                        <Tooltip placement={'right'} title={<Typography>{user.data.name}</Typography>} arrow>
                            <MenuItem
                                selected={(!isViewingOrg && !admin) || userMenuOpen}
                                onClick={() => setUserMenuOpen(true)}
                            >
                                <ListItemAvatar>
                                    <Badge
                                        overlap={'circular'}
                                        color={'secondary'}
                                        badgeContent={user.data.unread_notifications_count}
                                        max={99}
                                    >
                                        <Avatar alt={user.data.name} src={user.data.avatar_route ?? imagePaths.user}>
                                            {user.data.name}
                                        </Avatar>
                                    </Badge>
                                </ListItemAvatar>
                            </MenuItem>
                        </Tooltip>
                        {user.data.is_verified && <AddOrganization {...{ asMenuItem: true }} />}
                        {user.data.is_verified &&
                            user.data.pending_organization_members_count !== undefined &&
                            user.data.pending_organization_members_count > 0 && (
                                <Tooltip
                                    placement={'right'}
                                    title={<Typography>Pending Organizations</Typography>}
                                    arrow
                                >
                                    <MenuItem selected={location.pathname === '/home/pending-organizations'}>
                                        <Link
                                            onClick={toggleDrawer}
                                            component={RouterLink}
                                            to={'/home/pending-organizations'}
                                        >
                                            <ListItemAvatar>
                                                <Badge
                                                    overlap={'circular'}
                                                    color={'secondary'}
                                                    badgeContent={user.data.pending_organization_members_count}
                                                    max={99}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.info.main,
                                                        }}
                                                    >
                                                        <Pending sx={{ fontSize: 28 }} />
                                                    </Avatar>
                                                </Badge>
                                            </ListItemAvatar>
                                        </Link>
                                    </MenuItem>
                                </Tooltip>
                            )}
                        {user.data.is_verified &&
                            (organizations
                                ? organizations.map((org) => (
                                      <Tooltip
                                          key={org.id}
                                          placement={'right'}
                                          title={<Typography>{org.name}</Typography>}
                                          arrow
                                      >
                                          <MenuItem
                                              onClick={handleOrganizationClicked}
                                              selected={organization?.id === org.id}
                                          >
                                              {isViewingOrg && organization?.id === org.id ? (
                                                  <ListItemAvatar>
                                                      <Avatar
                                                          alt={org.name}
                                                          src={org.avatar_route ?? imagePaths.organization}
                                                      >
                                                          {org.name}
                                                      </Avatar>
                                                  </ListItemAvatar>
                                              ) : (
                                                  <Link
                                                      onClick={toggleDrawer}
                                                      component={RouterLink}
                                                      to={`/organizations/${org.id}`}
                                                  >
                                                      <ListItemAvatar>
                                                          <Avatar
                                                              alt={org.name}
                                                              src={org.avatar_route ?? imagePaths.organization}
                                                          >
                                                              {org.name}
                                                          </Avatar>
                                                      </ListItemAvatar>
                                                  </Link>
                                              )}
                                          </MenuItem>
                                      </Tooltip>
                                  ))
                                : [...Array(3)].map((_, i) => (
                                      <MenuItem sx={{ p: 0, my: 1 }} key={i} disabled={true}>
                                          <Skeleton animation={'wave'} variant={'circular'} height={40} width={40} />
                                      </MenuItem>
                                  )))}
                    </MenuList>
                )}
                {user.data?.is_admin && (
                    <div className={classes.leftColumnMenuBottom}>
                        <MenuList>
                            <Tooltip placement={'right'} title={<Typography>Admin Menu</Typography>} arrow>
                                <MenuItem
                                    selected={location.pathname.includes('/admin')}
                                    onClick={() => setUserMenuOpen(false)}
                                >
                                    {location.pathname.includes('/admin') ? (
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.error.main,
                                                }}
                                            >
                                                <AdminPanelSettings sx={{ fontSize: 28 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                    ) : (
                                        <Link onClick={toggleDrawer} component={RouterLink} to={`/admin`}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: (theme) => theme.palette.error.main,
                                                    }}
                                                >
                                                    <AdminPanelSettings sx={{ fontSize: 28 }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                        </Link>
                                    )}
                                </MenuItem>
                            </Tooltip>
                        </MenuList>
                    </div>
                )}
            </div>
            <div className={classes.rightColumn}>
                {user.data &&
                    (isViewingOrg && !userMenuOpen ? (
                        <OrganizationSidebar {...{ toggleDrawer }} />
                    ) : admin && !userMenuOpen ? (
                        <AdminMenu {...{ toggleDrawer }} />
                    ) : (
                        <UserSidebar
                            {...{
                                user: user.data,
                                darkMode: user.settings.darkMode,
                                alertSounds: user.settings.alertSounds,
                                toggleDrawer,
                            }}
                        />
                    ))}
            </div>
        </Stack>
    );
};

const useStyles = makeStyles<{ inDrawer?: boolean }>()((theme, { inDrawer }) => ({
    sidebar: {
        height: '100%',
        borderTopRightRadius: theme.spacing(1),
        marginRight: inDrawer ? 0 : theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[8],
    },
    leftColumn: {
        width: 64,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
    leftColumnMenu: {
        marginTop: 0,
        paddingTop: 0,
        '& .MuiButtonBase-root': {
            paddingLeft: theme.spacing(1.5),
        },
        paddingBottom: 0,
    },
    leftColumnMenuBottom: {
        display: 'flex',
        flex: 1,
        alignItems: 'end',
        '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& .MuiButtonBase-root': {
            paddingLeft: theme.spacing(1.5),
        },
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
}));

export default Sidebar;
