const Landing = () => {
    // const { classes } = useStyles();

    return (
        <>
            {/*<iframe*/}
            {/*    title={'landing'}*/}
            {/*    // allow={'*'}*/}
            {/*    style={{ border: 0 }}*/}
            {/*    // allowFullScreen={true}*/}
            {/*    loading={'eager'}*/}
            {/*    className={classes.root}*/}
            {/*    // seamless={true}*/}
            {/*    // scrolling={'auto'}*/}
            {/*    lib={'https://quiet-head-083038.framer.app/'}*/}
            {/*/>*/}
            <embed src={'https://quiet-head-083038.framer.app/'} height={'100%'} />
        </>
    );
};

export default Landing;
